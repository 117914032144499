<template>
  <v-card flat>
    <v-card-title>
      Pondr Terms
    </v-card-title>
    <v-card-text>
      By using this app you agree to allow us to access information we need to help you manage tasks, events, contacts, and files.  You are using this app as is and any
      damage to your data is both unintentional and your own responsibility.  If you do not wish to allow this access, do not use this app.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'terms'
}
</script>

<style scoped>

</style>
