<template>
  <v-card flat>
    <v-card-title>
      Pondr Privacy
    </v-card-title>
    <v-card-text>
      We will never use any information you provide for any reason other than helping you manage your own information.  We don't market, we don't sell information, and we never will.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'privacy'
}
</script>

<style scoped>

</style>
